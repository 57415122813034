import * as React from 'react';
import DemoButtons from './Utiilities/DemoButtons';
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import ImageSlider from './Utiilities/ImageSlider';
import Plans from './Utiilities/Plans';
import Accordion from './Utiilities/AccordionItem';
import Lightbox from './Utiilities/Lightbox';
import SpecailButtons from './Utiilities/SpecialButtons';
import Header from './Header and Footer/Header';
import Footer from './Header and Footer/Footer';
import ScrollToTop from './Utiilities/ScrolltoTop';
import G2Badge from './Utiilities/G2Badge';
import TabsWithImage from './Utiilities/TabsWithImage';
import Availability from './Utiilities/Availability';
import OurClients from './Utiilities/OurClients';
import Integration from './Utiilities/Integration';
import { MyContext } from '../App';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from 'react';
import GetOfferPage from './Utiilities/GetOfferPage';
// import { mouseflow} from "react-mouseflow";

function ED5() {
   
    
        const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    
        React.useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 767);
            };
    
            window.addEventListener('resize', handleResize);
    
            return () => {
                window.removeEventListener('resize', handleResize);
            };
    }, []);
  

    window.dataLayer.push({
        event: 'pageview',
        page: {
            url: "http://localhost:3000/employee-directory-365",
            title: "ED365"
        }
    });

    const AppName = React.useContext(MyContext);
    const dynamicImages = [
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos1_RPf_AeXggA.png?updatedAt=1700627745162',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/Client%20logos2_fZX_JD70Nu.png?updatedAt=1700627744112',
    ];
    const accordionItems = [
        {
            title: `How to Create a HR Directory?`, content: (
                <ul>
                 To create an HR Directory:
                 <li>Open Your SharePoint Site: Open your SharePoint site where you want to create the directory. </li> 
                 <li>Create a List: Click + New List and name it "Employee Directory." </li>
                 <li>Add Details: Add columns for Name, Job Title, Department, Phone, Email, etc. </li>
                 <li>Fill the Directory: Enter employee details or import them from Excel. </li>
                 <li>Display the Directory: Add the list to a page using the List Web Part and customize the view. </li>
                 Your SharePoint employee directory is now live and ready to use! 
                </ul>
            )
        },
        {
            title: `Does Microsoft have an employee directory?`, content: (
                <ul>
                Microsoft 365 includes tools for an employee directory: 
                   <li>Microsoft Teams: Search for colleagues by name or department. </li>
                   <li>Outlook: Use the Global Address List for contact details. </li>
                   <li>Delve: Explore detailed profiles, org charts, and shared documents. </li>
                   <li>Easy-to-use interface. </li>
                   <li>Advanced search options. </li>
                   <li>Seamless integration with Microsoft 365. </li>
                   This makes managing and finding employees simple and efficient. 
                </ul>


            )
        },
        {
            title: `How to find SharePoint directory?`, content: (
                <ul>
                    To find a directory in SharePoint, such as an employee or document directory, follow these steps: 
                    <li>Go to Your SharePoint Site: Open the SharePoint site where the directory is stored. </li>
                    <li>Search in Site Contents: 
                        <li>Click the Settings gear icon (top-right corner) and select Site Contents.</li>
                        <li>Look for a list or library named after the directory (e.g., "Employee Directory"). </li>
                    </li>
                    <li>
                    Use the Search Bar: 
                    <li>Use the Search bar at the top of the SharePoint page. </li>
                    <li>Enter keywords like "directory" or "employee directory" to locate it quickly. </li>
                    </li>
                    <li>Check for Links or Pages: Some directories might be displayed on a custom page or as a link in the site’s navigation menu. </li>
                    <li>Ask the Admin: If you can’t find the directory, your SharePoint administrator can provide access or guidance. </li>
                    This should help you locate the directory you need! 
                </ul>


            )
        },
      
        {
            title: `What is Employee Directory system?`, content: (
                <ul>
                    Employee Directory system is a central hub where you can find information about everyone in your organization. 
                    <li>Basic Information: Names, job titles, departments, and contact details (phone, email, etc.). </li>
                    <li>Organizational Structure: Reporting hierarchy and team associations. </li>
                    <li>Profiles: Additional details like photos, work locations, and expertise. </li>
                </ul>


            )
        },


    ];
    const Edimages = [
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-ED/Seamless-integration_-9GbPXuMO.png?updatedAt=1705384671710',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-ED/Real-time-sync_RvMctcJTU.png?updatedAt=1705383933936',
        'https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-ED/Advance-search-filter_THl4Y7SS0.jpg?updatedAt=1705384438699',
    ];
    const planData = [
        {
            name: 'Standard',
            price: '$49',
            sup: '99',
            text: 'Standard Plan Features',
            textY: 'per month, billed yearly',
            features: ['50 Users', '2 Support tickets per year', 'Free updates via Microsoft store', 'Alphabet filtering', '1 Exclude options ', '1 Search options', 'Mobile responsiveness', 'Auto sync data from M365'],
        },
        {
            name: 'Plus',
            price: '$99',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Standard and...',
            features: ['Upto 100 users ', '4 support tickets per year', '3 Exclude options ', '3 Search options ', 'Choice to hide user properties', 'Configure SIP / Call protocols', 'Multiple profile image sync', 'Add as web part in Intranet'],
        },
        {
            name: 'Premium',
            price: '$149',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Plus, and...',
            features: ['Upto 200 users ', 'Add-on $50 for every 100 users', 'Unlimited support', 'Free updates with support', '5 Exclude options', '5 Search options ', 'Custom Fields', 'Sync Active directory attributes', 'Birthday and anniversary notifications', 'Custom logo', 'Organization chart', 'Custom quick link', 'Custom return page link', 'Available in 10+ languages', 'Modern SharePoint App', 'Advance top bars filters'],
        },
        {
            name: 'Enterprise',
            price: '$249',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Premium and...',
            features: ['Upto 300 users ', 'Add-on $50 for every 100 users', 'Custom column Search​', 'Filters - About me, Projects, Skills', 'Filters - School and Hobbies', 'Quicklink to custom profile pages', 'Executive Assistant relationship', 'Online presence with extended status', 'Calendar - free / busy information', 'Profile Imaged with text frames', 'Import users using CSV', 'QR Code ​', 'Exclude Bulk Shared Mailboxes​', 'Hide Mobile number selectively​', 'Guest Users ​', 'Dashboard', 'Non Microsoft 365 users​', 'Dedicated account manager', 'One free customization'],
        },
    ];
    const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState('');
    const openLightbox = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setLightboxOpen(true);
    };
    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    const tabs = [
        {
            id: 1, title: 'Collaboration', Heading: 'Simplify Collaboration with Online Employee Directory ',
            content: (
                <ul>
                   <li>Boost teamwork and efficiency with online employee directory software.</li>
                   <li>Seamlessly integrated with Microsoft 365, turning your directory into a central hub within SharePoint and Teams.</li>
                   <li>Quick access to important employee details, reducing platform-switching and improving focus. </li>
                   <li>Automatic updates ensure your employee information is always accurate and current across all platforms. </li>
                   <li>Customizable search and filter options make it simple to find and connect with colleagues. </li>
                   Empower your team to work smarter and stay connected with ease. 
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Integration_J10FfNdpZB.gif?updatedAt=1720500232501'
        },
        {
            id: 2, title: 'Features', Heading: 'Enhanced Search Features of Employee photo directory software ', content: (
                <ul>
                    <li>Easily find colleagues by name, department, skills, or custom details using powerful search and filtering options. </li>
                    <li>Save time in large organizations with advanced search functions that improve collaboration and efficiency. </li>
                    <li>Quick access to frequently used filters with saved search preferences and recent queries. </li>
                    <li>Visualize team dynamics with organizational chart integration, showing clear reporting structures for better understanding. </li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Searching_bszxfaq2js.gif?updatedAt=1720500232508'
        },
        {
            id: 3, title: 'Visualization', Heading: 'Interactive Organizational Visualizations in HR Directory 365', content: (
                <ul>
                   <li>Dynamic organizational charts that visually display company hierarchy and reporting lines. </li>
                   <li>Interactive charts help team members understand roles and assist managers in improving team structures for better collaboration and planning. </li>
                   <li>Customizable chart views allow users to focus on specific departments or teams for targeted insights. </li>
                   <li>Real-time updates ensure the organizational charts reflect the most current structure and personnel changes. </li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/visualization_viJFIn6Wt.gif?updatedAt=1720500231624'
        },
        {
            id: 4, title: 'Synchronization', Heading: 'Real-Time Data Synchronization of HR365 Employee Directory', content: (
                <ul>
                  <li>Real-time synchronization from Microsoft 365 ensures accurate and reliable employee data.</li>
                  <li>Seamless integration keeps employee records up-to-date and provides an efficient system for managing organizational data. </li>
                  <li>Instant updates to employee information are reflected across all integrated Microsoft 365 applications. </li>
                  <li>Automated alerts notify users of important updates, ensuring quick action is taken when needed. </li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/dataSync__gvNUPNCn.gif?updatedAt=1720500232251'
        },
        {
            id: 5, title: 'Security', Heading: ' Enhanced Confidentiality and Security: Employee Directory 365', content: (
                <ul>
                  <li>Strong security features within the Microsoft 365 framework safeguard sensitive employee information.</li>
                  <li>Prevents unauthorized access while ensuring compliance with organizational and industry standards. </li>
                  <li>Granular access controls allow administrators to define permissions and restrict data visibility based on user roles. </li>
                  <li>Regular security audits and compliance checks provide ongoing protection and peace of mind </li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/ED365/Security%20(1)_9wJphPYCa.gif?updatedAt=1720500232285'
        },
    ];
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Employee Directory for Microsoft 365 | Simplify Connections, Enhance Collaboration</title>
                    <meta name="description" content="Employee Directory 365 is a Microsoft 365 Certified app, reviewed by Microsoft for industry standards in security, compliance, and data protection." />
                </Helmet>
                <ScrollToTop />
                <Header />
                {/* <GetOfferPage Type="Image"/> */}
                <div className='HR_panel'>
                    <div className="HR_FlexCenterSpcBetween HR_ParentSection1" >

                        <div className='HR_Section1LeftSide '>
                            <h1 className='HR_primaryclr'>Easy Access, Stronger Teams – Improve Communication with Employee Directory 365</h1>
                            
                            <p className='HR_textclr'>Our modern HR Directory is built-in with Microsoft 365 to help you gain easy access to team information and foster stronger communication across your organization. </p>
                           
                        </div>
                        <div className='HR_Section1RightSide'>
                            <img alt='MainImage' src={"https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/ED365_sAcd5DKMg.png?updatedAt=1727764057869"}   />
                        </div>
                    </div>
                </div>
                <div>
                    <G2Badge
                        heading="Now Employee Directory 365 App is Available on Microsoft Teams"
                        imageUrl="https://ik.imagekit.io/zn4au2jftpm5/Apps365/Teams/MicrosoftTeams-image_En8P7Cj9x4.png?updatedAt=1725515030789" />
                </div>
                <div id="features" className="IdChanges"></div>
                <div>
                    <div  >
                        <TabsWithImage tabs={tabs} />
                    </div>
                </div>
                <div>
                    <Availability />
                </div>
                <div id="client" className="IdChanges"></div>
                <div className='HR_whitesection'>
                    <h2 className='HR_heading HR_MT' >Our Clients
                    </h2>
                    <div className='HR_MT'>
                        <OurClients />
                    </div>
                </div>
                <div id="integration" className="IdChangesIntegration"></div>
                <div className='integartionPT'>
                    <Integration appName={"Employee Directory 365"} />
                </div>
                <div className="IdChangesIntegration"></div>
                <div className='HR_whitesection'>
                    <h2 className='HR_heading HR_MT' id="pricing">Employee Directory 365 Plans
                    </h2>
                    <div className='HR_MT'>
                        <div>
                            <Plans plans={planData} />
                        </div>
                    </div>
                    <SpecailButtons userCount={"1000+"} />
                    <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                    </h3>
                    <div className='Hr_flex HR_MT'>
                        <DemoButtons />
                    </div>
                </div>
                <span className='privacytext'></span>
                <div id="faq" className="IdChangesIntegration"></div>
                <div className='HR_FAQ' >

                    <div>
                        <h3 className='HR_faq'>Frequently Asked Questions</h3>
                        <Accordion items={accordionItems} />
                    </div>


                </div>
                <Footer />
            </HelmetProvider> </>
    )

}
export default ED5;