import * as React from "react";
import DemoButtons from "./Utiilities/DemoButtons";
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import Plans from "./Utiilities/Plans";
import Accordion from "./Utiilities/AccordionItem";
import SpecailButtons from "./Utiilities/SpecialButtons";
import Header from "./Header and Footer/Header";
import Footer from "./Header and Footer/Footer";
import ScrollToTop from "./Utiilities/ScrolltoTop";
import G2Badge from "./Utiilities/G2Badge";
import TabsWithImage from "./Utiilities/TabsWithImage";
import Availability from "./Utiilities/Availability";
import OurClients from "./Utiilities/OurClients";
import Integration from "./Utiilities/Integration";
import { MyContext } from "../App";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from 'react';
import GetOfferPage from "./Utiilities/GetOfferPage";
// import { mouseflow} from "react-mouseflow";
function HD8() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // React.useEffect(() => {
    //   document.title = "new title" 
    // }, []);
    // React.useEffect(() => {
    //   mouseflow.initialize("d74e23c0-0490-4d54-925d-b4ea1ae7151a");
    //   }, []);
    React.useEffect(() => { document.title = "Helpdesk Software, IT Ticketing System - Helpdesk 365"; const metaDescription = document.querySelector("meta[name='description']"); if (metaDescription) { metaDescription.setAttribute("content", "Streamline workflows with a Microsoft helpdesk. Try now for faster ticket resolution. Helpdesk software for IT and internal support.!"); } else { const newMeta = document.createElement("meta"); newMeta.name = "description"; newMeta.content = "Streamline workflows with a Microsoft helpdesk. Try now for faster ticket resolution. Helpdesk software for IT and internal support."; document.head.appendChild(newMeta); } }, []);
    const AppName = React.useContext(MyContext);
    const planData = [
        {
            name: "Standard",
            price: "$11",
            sup: "99",
            text: "Standard Plan Features",
            textY: "per month, billed yearly",
            features: [
                "Minimum 5 users",
                "25 ticket requesters",
                "2 Support tickets per year",
                "Free updates via Microsoft store",
                "Integrates with Office 365",
                "SharePoint-hosted app",
                "Data stays in your SharePoint",
                "Custom request types",
                "Mobile responsive",
                "Multiple roles",
                "Smart notifications",
                "Self Service web forms",
                "Multiple departments groups",
                "Multiple departments groups",
                "Collaboration with ticket requester",
            ],
        },
        {
            name: "Plus",
            price: "$24",
            sup: "99",
            textY: "per month, billed yearly",
            text: "Everything in Standard and...",
            features: [
                "Minimum 5 users",
                "50 ticket requesters",
                "Unlimited support tickets",
                "Commonly users service fields",
                "Dashboard",
                "Canned responses",
                "Custom signatures",
                "Custom ticket status",
                "Custom ticket sequence",
                "Ticket fields customization",
                "Private Notes",
                "Ticket Splitting & Merging",
            ],
        },
        {
            name: "Premium",
            price: "$44",
            sup: "99",
            textY: "per month, billed yearly",
            text: "Everything in Plus, and...",
            features: [
                "Minimum 5 users",
                "Unlimited ticket requesters",
                "Unlimited support",
                "Customer satisfaction Surveys",
                "Create Sub tickets​",
                "Free updates with support",
                "Create new ticket from emails",
                "Email templates",
                "Auto assign tickets",
                "Auto close tickets",
                "Auto capture email attachments",
                "Integrate with Asset Management",
                "Block spammers and domains",
                "Onelevel sub services",
                "Custom views",
                "Custom columns",
                "Available in 10 Language",
            ],
        },
        {
            name: "Enterprise",
            price: "$64",
            sup: "99",
            textY: "per month, billed yearly",
            text: "Everything in Premium and...",
            features: [
                "Minimum 5 users ",
                "Dedicated account manager",
                "Kanban View & Modern View",
                "Escalate Level 1 & Level 2",
                "Multiple SLA choices",
                "Service and sub-service fields",
                "Auto escalate tickets",
                "Ticket due date reminders​",
                "Ticket aging report",
                "Knowledge - base",
                "Approval workflows",
                "Custom forms",
                "Multiple mailboxes department wise",
                "Rules & automation",
                "Archive",
                "Create KB from tickets",
                "Create ticket through MS Forms",
                "API connectivity",
                "Connect with Power BI & Power Automate",
                "One free customization*",

            ],
        },
    ];
    const data = [
        {
            heading: "Respond quickly and consistently",
            imageUrl:
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/TicketDetails_HD365_M4XwPCWlv.png?updatedAt=1685598825749",
        },
        {
            p: "View, reply and repeat. With canned actions we’ve made this really easy",
            heading: "Connect Helpdesk 365 with your other apps",
            imageUrl:
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/powerplatformtoolset_ReRdfzh4N.png?updatedAt=1700466299384",
        },
        {
            heading: "Collaborate with your teammates",
            imageUrl:
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Comments_HD365_x7hBhTdyM.png?updatedAt=1685503315168",
        },
        {
            heading: "Simple & Easy ticketing system",
            imageUrl:
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Settings_HD365_4vXqK4vRw_.png?updatedAt=1685422911172",
        },
        {
            heading: "Intra-department Support",
            imageUrl:
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Homepage_HD365_noFv1A00F.png?updatedAt=1685424223912",
        },
        {
            heading: "Find & reward your stars ",
            imageUrl:
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Customer_Survey_HD365_3Qei9O4HT.png?updatedAt=1685588116513",
        },

        // Add more objects as needed
    ];

    const accordionItems = [
        {
            title: "How do I create a Microsoft 365 ticketing system?",
            content: (
                <ul>
                    To create a ticket in Microsoft office ticketing system:
                    <li>
                    Open Microsoft Teams and go to the Helpdesk 365 app.
                    </li>
                    <li>
                        Click New Ticket to start.
                    </li>
                    <li>
                        Fill in the details, including the issue, priority, and any attachments.
                    </li>
                    <li>
                        Submit the ticket, and it’s instantly logged.
                    </li>
                    You can track updates and communicate with the support team directly in Teams.
                </ul>
            ),
        },
        {
            title: "Is SharePoint a Ticketing System?",
            content: (<ul>
                SharePoint a collaboration platform for managing documents and workflows for millions of Microsoft 365 users. However, you can customize it for ticket tracking and effective communication using:
                <li>
                    Lists: To log and track tickets.
                </li>
                <li>
                    Workflows: For automating assignments and updates.
                </li>
                <li>
                    Power Automate: For advanced automation.
                </li>
                For a more robust ticketing solution, tools like Helpdesk 365 integrate seamlessly with SharePoint. So that you can easily manage your SharePoint ticketing system with more efficiency.
            </ul>)
        },
        {
            title: "What is SharePoint Ticket System?",
            content: "SharePoint is a web-based platform that improves your organization efficiency by simplifying data management and access. SharePoint serves as an enterprise information hub, easily customizable to support intranet, extranet, and internet sites."
        },

    ];
    // const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    // const [selectedImageUrl, setSelectedImageUrl] = React.useState("");

    // const openLightbox = (imageUrl) => {
    //   setSelectedImageUrl(imageUrl);
    //   setLightboxOpen(true);
    // };

    // const closeLightbox = () => {
    //   setLightboxOpen(false);
    // };
    const tabs1 = [
        {
            id: 1, title: 'Focus on what you need', Heading: 'Prioritize What Truly Matters: Simplify with Microsoft office ticketing system', content: (<ul>
                <li>Simplify ticket management with Microsoft ticketing system office 365 that organizes tickets by priority, category, time, and status.</li>
                <li>Effortless request submission through the SharePoint portal via email or an embedded web part.</li>
                <li>Seamless collaboration with Microsoft Teams integration, offering conversational support for your team.</li>
                <li>Boost productivity and satisfaction by streamlining request tracking across IT, HR, and other departments.</li>
            </ul>), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/Recording4-ezgif.com-video-to-gif-converter_1t1BQO-ac.gif?updatedAt=1718713728955'
        },
        {
            id: 2, title: 'Ticket Management', Heading: 'Effortless and Reliable Service Ticketing System Features You’ll Love', content: (
                <ul>
                    <li>Easily find colleagues by name, department, skills, or custom details using powerful search and filtering options.</li>
                    <li>Save time in large organizations with advanced search functions that improve collaboration and efficiency.</li>
                    <li>Quick access to frequently used filters with saved search preferences and recent queries.</li>
                    <li>Visualize team dynamics with organizational chart integration, showing clear reporting structures for better understanding.</li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/TicketManagement_NawCmGYkO.gif?updatedAt=1719903031399'
        },
        {
            id: 3, title: 'SLA on cloud-based ', Heading: 'Improve Efficiency with SLAs in Our o365 ticketing system', content: (
                <ul>
                  Make service delivery smoother and more dependable with HR365’s Microsoft dynamics ticketing system —designed to keep both your team and customers happy.
                    <li>Reliable Service with SLAs: Count on consistent support and keep customers satisfied with strong Service Level Agreements (SLAs).</li>
                    <li>Actionable Insights: Use detailed reports and analytics to spot performance gaps and improve your service.</li>
                    <li>Timely Responses: Automated SLA tracking and alerts help your team stay on top, ensuring quick responses and fast issue resolution.</li>
                    <li>Tailored to Your Needs: Customize SLA templates to create service levels that fit your organization’s goals perfectly.</li>
                </ul>

            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/SLA_SKQ5cy1rQf.gif?updatedAt=1719814962251'
        },
        {
            id: 4, title: 'Easy Customization', Heading: 'Powerapps ticketing system Work Perfectly for You with Easy Customization', content: (
                <ul>
                    <li>Improve your support experience with our flexible Microsoft office 365 ticketing system seamlessly integrated with SharePoint.</li>
                    <li>Easily assign tickets, prioritize tasks, and keep everyone updated with streamlined notifications.</li>
                    <li>Boosts productivity, streamlines workflows, and speeds up response times, creating a supportive environment that goes above and beyond.</li>
                    <li>Advanced reporting and analytics help you spot trends and improve team performance for continuous growth.</li>
                    <li>Integration with Microsoft Teams allows your support staff to collaborate and communicate in real-time, solving issues faster.</li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/approve_Q5cb5xjOH.gif?updatedAt=1719898618060'
        },

    ];
    const tabs2 = [
        {
            id: 1, title: ' Support Experience ', Heading: 'Transform Your Support with Cutting-edge Office 365 Ticketing System Features', content: (
                <ul>
                    <li>
                        Empower customer support with our comprehensive ticketing system, boosting productivity.
                    </li>
                    <li>
                        Clients can easily request tickets and get instant status updates through our intuitive platform.
                    </li>
                    <li>
                        The SharePoint ticketing system not only tracks tickets but also helps identify solutions, with quick access to historical data and a knowledge base, accelerating issue resolution and reducing new ticket submissions.
                    </li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/Supportexp_eht3Bt82e6.gif?updatedAt=1719903031166'
        },
        {
            id: 2, title: 'Security Measures', Heading: ' Enhanced Security for Identity and Access Management', content: (
                <ul>
                    <li>
                    Office 365 ticketing system ensures security with robust identity and access management. Integrated with Microsoft 365, it employs a safe strategy with multiple security layers.
                    </li>
                    <li>
                        These measures, along with stringent practices, protect your Microsoft ticketing system against threats and unauthorized access. For detailed insights into our security protocols, contact Apps365.
                    </li>
                    <li>
                        Regular security updates and vulnerability assessments ensure ongoing protection and adaptation to emerging threats.
                    </li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/Securitymeasures_SLoTGlIE5.gif?updatedAt=1719903031258'
        },
        {
            id: 3, title: 'Boost Productivity', Heading: 'Transform Customer Support and Drive Operational Excellence with o365 ticketing system', content: (
                <ul>
                    <li>
                    Ticket system support enhances customer service by providing insightful reports to gauge satisfaction and optimize team performance.
                    </li>
                    <li>
                        It uncovers actionable insights for immediate improvements, ensuring a seamless experience for both teams and clients.
                    </li>
                    <li>
                        Automated ticketing and workflow management streamline support processes, reducing response times and increasing efficiency.
                    </li>
                    <li>
                        Customizable dashboards and metrics allow for tailored performance tracking and reporting to meet specific business needs.
                    </li>
                </ul>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/boast_xwE5iohIU.gif?updatedAt=1719904751386'
        },
        {
            id: 4, title: 'Rules and Automation', Heading: 'Drive Efficiency with Customized Rules and Automation', content: (
                <ul>
                    <li>
                        Boost operational efficiency with Apps365’s 'Rule & Automation' feature. Customize and automate ticket management by creating advanced rules for generating, assigning, and routing tickets based on your needs.
                    </li>
                    <li>
                        This feature streamlines ITSM operations, ensures prompt attention to every ticket, and saves valuable time and resources.
                    </li>
                    <li>
                        Automated escalation protocols ensure critical issues receive immediate attention, reducing resolution times for high-priority tickets.
                    </li>
                    <li>
                        Flexible rule configuration allows for dynamic adjustments to workflows, adapting to changing operational requirements and priorities.
                    </li>
                </ul>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/Apps365/GIF/HD365/Rules&automation_qYBfGojFe.gif?updatedAt=1719903031561'
        },

    ];
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Helpdesk Software for Microsoft 365 | Fast, Secure, and Reliable</title>
                    <meta name="description" content="Helpdesk 365: Secure ticketing for IT, HR, and Finance. Seamlessly integrates with Microsoft 365. Easy to deploy, customizable, and efficient." />
                </Helmet>
                <ScrollToTop />

                <Header />
                <div className="HR_panel">
                    <div className="HR_FlexCenterSpcBetween HR_ParentSection1">
                        <div className="HR_Section1LeftSide">
                            <h1 className="HR_primaryclr  ">
                            Smarter Support, Happier Teams – Faster Ticketing with Helpdesk 365
                            </h1>
                            <p className="HR_textclr">
                            Manage tickets easily with our Microsoft 365 ticketing system that works perfectly with Microsoft 365. Help your team give faster and better support without any hassle.
                            </p>

                        </div>
                        <div className="HR_Section1RightSide">
                            <img alt="MainImage" src="https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/Helpdesk_OjlohM6cD.png?updatedAt=1718610768162"   />
                        </div>
                    </div>
                </div>
                {/* <GetOfferPage Type="Image"/> */}
                <div>
                    <G2Badge
                        heading="Now Helpdesk 365 App is Available on Microsoft Teams"
                        imageUrl="https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-Helpdesk/HD365%20Teams_bGn7Gudo4J.png?updatedAt=1701258105168" />
                </div>
                <div id="features" className="IdChanges"></div>
                <div >
                    <TabsWithImage tabs={tabs1} />
                </div>
                <div  >
                    <TabsWithImage tabs={tabs2} />
                </div>
                <div>
                    <Availability />
                </div>
                <div id="client" className="IdChanges"></div>
                <div className='HR_whitesection' >
                    <h2 className='HR_heading HR_MT' >Our Clients
                    </h2>

                    <div className='HR_MT'>
                        <OurClients />
                    </div>
                </div>
                <div id="integration" className="IdChangesIntegration"></div>
                <div className='integartionPT'>
                    <Integration appName={"Helpdesk 365"} />

                </div>
                <div className="IdChanges"></div>
                <div className="HR_whitesection">
                    <h2 className="HR_heading HR_MT" id="pricing" >
                        Helpdesk 365 Plans
                    </h2>
                    <div className="HR_MT">
                        <div>
                            <Plans plans={planData} />
                        </div>
                    </div>

                    <SpecailButtons userCount={"50+"} />
                    <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                    </h3>
                    <div className='Hr_flex HR_MT'>
                        <DemoButtons />
                    </div>
                </div>
                <span className='privacytext'></span>
                <div id="faq" className="IdChangesIntegration"></div>
                <div className='HR_FAQ'>

                    <div >
                        <h3 className='HR_faq'>Frequently Asked Questions</h3>
                        <Accordion items={accordionItems} />
                    </div>


                </div>
                <Footer />
            </HelmetProvider>
        </>
    );
}
export default HD8;
