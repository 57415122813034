import * as React from 'react';
import DemoButtons from './Utiilities/DemoButtons';
import ImageSction1 from "../Assests/Images/Ed365/ImageSection1.png";
import ImageSlider from './Utiilities/ImageSlider';
import Plans from './Utiilities/Plans';
import Accordion from './Utiilities/AccordionItem';
import Lightbox from './Utiilities/Lightbox';
import SpecailButtons from './Utiilities/SpecialButtons';
import Header from './Header and Footer/Header';
import Footer from './Header and Footer/Footer';
import ScrollToTop from './Utiilities/ScrolltoTop';
import G2Badge from './Utiilities/G2Badge';
import TabsWithImage from './Utiilities/TabsWithImage';
import Availability from './Utiilities/Availability';
import OurClients from './Utiilities/OurClients';
import Integration from './Utiilities/Integration';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from 'react';
import { MyContext } from '../App';

import Thankyou from './Thankyou';
import GetOfferPage from './Utiilities/GetOfferPage';
function TM365() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const AppName = React.useContext(MyContext)
    const planData = [
        {
            name: 'Standard',
            price: '$49',
            sup: '99',
            text: 'Standard Plan Features',
            textY: 'per month, billed yearly',
            features: ['Upto 50 users', '2 support tickets per annum', 'Free updates via MS store', 'Integrates with Office 365', 'Save & submit requests​', 'Approve & reject requests​', 'Cancel leave request', 'Email notifications​', 'Customized leave types​', 'Multiple roles – four types​', 'Customize leave year​', 'Work week pattern setup'],

        },
        {
            name: 'Plus',
            price: '$129',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Standard and...',
            features: ['Upto 100 users', '4 support tickets per annum', 'Request half day & two-hour leaves​', 'Comp Off / In Lieu of leaves', 'Location based public holidays', 'Level / Grade based leaves', 'Tenure based leaves​', 'Custom theme, brand & logo​', 'Non working days exclusion', 'Submit leaves on behalf of users'],
        },
        {
            name: 'Premium',
            price: '$199',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Plus, and...',
            features: ['200 users', 'Add-on $25 for every 50 users', 'Free update with support​', 'Unlimited support​', 'Accrual based leaves​', 'Hourly leave approval​', 'Approval delegation​', 'Reminder notification to approvers', 'Leave cancellation ​', 'Auto approvals ​', 'Approve on behalf of manager​', 'Leave templates', 'Multi language'],
        },
        {
            name: 'Enterprise',
            price: '$249',
            sup: '99',
            textY: 'per month, billed yearly',
            text: 'Everything in Premium and...',
            features: ['300 users', 'Add-on $25 for every 50 users', 'Recurrence leaves ​', 'Teams calendar​', 'Multilevel approval workflow', '30 Type of leaves', 'Customize with Power Automate', 'API connectivity​', 'Connect with Power BI​', 'One free customization'],
        },
    ];
    const data = [

        {
            heading: 'Application home – Leaves information at a glance ',
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/TMP_SPFX/ApplicationHome.png?updatedAt=1638250722692',
            p: `Check status of requested leaves at the Home page.`,
            p1: `Type-wise leaves balance visibility.`,
            p2: `Managers can review leave requests.`,
        },
        {

            heading: 'Managers can review leave requests.',
            p: `You can give your nod to all your subordinates' leave requests with just a couple of clicks.`,
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/TMP_SPFX/BulkApproval.png?updatedAt=1638250734127',
        },
        {

            heading: 'Collaborate with your teammates',
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/HDP_Regular/Comments_HD365_x7hBhTdyM.png?updatedAt=1685503315168',
        },
        {
            heading: 'Control to the HR',
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/TMP_SPFX/ControlToTheHR.png?updatedAt=1638250711864',
            p: `The HR user can apply and approve leaves on behalf of other users. Manager's role can be delegated.`,
        },
        {
            heading: 'Dynamic reporting',
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/TMP_SPFX/DynamicReporting.png?updatedAt=1638359954432',
            p: `Leaves statistics through charts. Desired report can be easily exported.Filters to narrow down search results.`
        },
        {
            heading: `Connect Time off Manager 365 with your other apps`,
            imageUrl: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/powerplatformtoolset_ReRdfzh4N.png?updatedAt=1700466299384',
        },

        // Add more objects as needed
    ];
    const accordionItems = [
        {
            title: 'From where the employees can apply leave? ', content: `An employee can apply his leave from their self service portal or from the application.

        First click on New Request button.
        
        Once pop-up is opened, apply your leave request and as soon as user clicks submit for approve, it would get an email to the manager. As well as that leave would be added in manager action.` },
        {
            title: 'Can a HR apply leave on behalf of the users?', content: `As a HR executive, this tool gives the ability to apply leave on behalf of the user.

            Go to HR Actions tab and click on “Add leave on behalf of user” button.
            
            Once the pop-up opens, enter employee name and email and apply the leave on behalf of that employee and click on submit for approve.` },
        {
            title: 'Can I install multiple instances of Time off Manager 365?',
            content: 'Yes, you can install multiple instances of the application as long as it is in one Microsoft 365 tenant and total number of users of all the instances installed remain within the user limit as per your plan or license you purchased.'
        }, {
            title: 'Where can we define annual casual paid leaves & medical leaves?',
            content: `We can configure leave management application settings from the ${AppName} portal.

            Annual casual paid leaves, medical leaves, number of restricted holidays, annual leave start date and end date can be defined in settings page of ${AppName}. The calendar year also can be defined here.`
        }, {
            title: "From where can a manager see all the requests for leaves?",
            content: `Manager can see all the requests for leaves for a particular day in “Manager Action” tab.`
        }, {
            title: `Do we have option of customizing or adding more leave types?`,
            content: `Yes. We can customize or add more leave types as per requirement.

            First go to “Leave Settings” tab and select “Leave Type” and add leave type as per your requirement.
            
            This leave type would be added in New request form while adding new leave request.`
        }, {
            title: `Where can employee see his status of leave requests?`,
            content: `An employee can see the status of all his previous leave requests where those are in pending, approved or rejected in the home page from this portal.`
        }, {
            title: `Where can we define various email templates?`,
            content: `First go to “System Settings” tab then select Email Settings.

            Here you can define email body, email signatures, you can set it up as per your organization needs.`
        }, {
            title: `Can we add additional appprovals for approving the leave request?`,
            content: `Yes, we can add additional approvers in Settings by selecting the “Second level approval for LAMS App” and two level approvals would be added in the LAMS application while adding Leave request.`
        }, {
            title: `Does the manager has the ability for bulk approval?`,
            content: `Yes. Manager has the ability for bulk approval.

            First click on “Click here for Bulk Approval”.
            
            Once pop-up opens, select the leave request you want to approve or reject and select Approve or Reject button accordingly.`
        },
        {
            title: `Is there any provision for applying Comp-off leave request?`,
            content: `Employee can raise a comp-off for any previous day he worked on.

            First, go to “Add Comp-off” tab, then click on “Add” button.
            
            Once the pop-up opens, apply your Comp-off leave request, and as soon as user clicks submit for approve,
            
            that would stay and get added into the leave record.`
        }

    ];
    const [isLightboxOpen, setLightboxOpen] = React.useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = React.useState('');

    const openLightbox = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    const tabs = [
        {
            id: 1, title: 'Integration', Heading: 'Unified Microsoft 365 & Teams Integration ', content: (
                <ul>
                    <li>
                        Effortlessly manage time off within your existing workspace, with seamless integration into Microsoft 365 and Teams, allowing employees and managers to handle requests without leaving their workflow.
                    </li>
                    <br />
                    <li>
                        The integration fosters instant communication among team members regarding leave requests, enhancing collaboration and ensuring smooth coordination for effective coverage.
                    </li>
                </ul>
            )

            , image: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/applyleaves.png?updatedAt=1627374807039'
        },
        {
            id: 2, title: 'Workflows', Heading: 'Automation Workflows with Intuitive Interface', content: (
                <>
                    <ul>
                        <li><b>Automated Leave Approval Workflows</b></li>
                        <p style={{ margin: "0" }}>Optimize time-off management with automated workflows that streamline notifications, approvals, and rejections. Eliminating manual tasks for efficient, consistent results. Save time and enhance operational effectiveness with ease.</p>
                        <br />
                        <li><b>User Friendly Interface</b></li>
                        <p style={{ margin: "0" }}>Our platform empowers employees and managers to handle time-off requests with ease. Its intuitive interface streamlines the process, reducing errors and boosting efficiency for all.</p>
                    </ul>
                </>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/Timeoff_manager.png?updatedAt=1627374752851'
        },
        {
            id: 3, title: 'Mobile-Friendly', Heading: 'Mobile-Friendly Access', content: (
                <>
                    <ul>
                        <li>Employees can easily request time off and track their leave balances from mobile, with seamless access whether they’re on a desktop at the office or using a mobile device on the go.</li>
                        <br />
                        <li>Receive immediate updates regarding their leave requests, including approvals and denials, directly through their mobile devices, enhancing communication and clarity.</li>
                    </ul>
                </>
            ), image: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/Time_Off_Manager_Plus_Mobile_View_Images.png?updatedAt=1627374781329'
        },
        {
            id: 4, title: 'Reporting', Heading: 'Comprehensive Tracking and Reporting', content: (
                <>
                    <ul>
                        <li><b>Real-Time Balance Tracking</b></li>
                        <p style={{ margin: "0" }}>Access real-time leave balances for both employees and managers, promoting transparency and eliminating manual inquiries. This immediate visibility supports informed time-off decisions and fosters a culture of openness and efficiency.</p>
                        <br />
                        <li><b>Comprehensive Reporting</b></li>
                        <p style={{ margin: "0" }}>Create insightful reports on leave usage to support HR teams in managing leave effectively. With these insights, you can address issues early and improve workforce productivity.</p>
                    </ul>
                </>
            )
            ,
            image: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/Timeoff_manager.png?updatedAt=1627374752851'
        },
        {
            id: 5, title: 'Leave Management', Heading: 'Centralized Leave Management', content: (

                <>
                    <ul>
                        <li>Time Off Manager 365 enables organizations to centrally handle employee leave requests, approvals, and balances. Its intuitive interface, integrated with SharePoint and Microsoft Teams, allows employees to easily request leaves, while managers can review, approve, or decline requests seamlessly within their everyday tools.</li>
                        <br />
                        <li>Tailor your company’s unique leave policies with customizable options, including leave accrual and allocation, all within the leave management system.</li>
                    </ul>
                </>
            ),
            image: 'https://ik.imagekit.io/zn4au2jftpm5/hr365/applyleaves.png?updatedAt=1627374807039'
        },
    ];
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Effortless Time-Off Management – Because Every Break Matters!</title>
                    <meta name="description" content="Streamline leave management with Time Off Manager – seamless approvals, custom policies, and Microsoft 365 integration for a stress-free experience!" />
                </Helmet>
                <ScrollToTop />
                <Header />

                <div className='HR_panel'>

                    <div className="HR_FlexCenterSpcBetween HR_ParentSection1" >

                        <div className='HR_Section1LeftSide'>
                            <h1 className='HR_primaryclr'>Work-Life Harmony Starts Here – Efficient and Effortless Leave Management!</h1>
                            <p className='HR_textclr'>Give your team the gift of balance with Time Off Manager – the smarter way to handle leaves and time-off requests. Simplify approvals, customize policies, and integrate effortlessly with Microsoft 365, all while empowering employees to recharge and thrive. Say goodbye to manual processes and hello to a seamless, stress-free experience!</p>
                            <h3 className='HR_primaryclr '>Get started with 14 days risk free trial.</h3>
                            <h3 className='HR_primaryclr '>No credit card details required</h3>
                            <div className='HR_OuterDemoButtons'>
                                <DemoButtons />

                            </div>
                        </div>
                        <div className='HR_Section1RightSide'>
                            {/* <img alt='MainImage' src={ImageSction1}   /> */}
                            <img alt='MainImage' src="https://ik.imagekit.io/zn4au2jftpm5/Apps365/productPAGES/Timeoffmanager.png?updatedAt=1718777116752"   />
                        </div>
                    </div>
                </div>
                {/* <GetOfferPage Type="Image"/> */}

                <div>
                    <G2Badge
                        heading="Now Time off Manager 365 App is Available on Microsoft Teams"
                        imageUrl="https://ik.imagekit.io/zn4au2jftpm5/Apps365/Teams/Timeoffmanager_HEexcXgNk.png?updatedAt=1720507129715" />
                </div>
                <div id="features" className="IdChanges"></div>
                <div id='features'>
                    <TabsWithImage tabs={tabs} />
                </div>
                <div>
                    <Availability />
                </div>
                <div className='HR_whitesection' id='client'>
                    <h2 className='HR_heading HR_MT' >Our Clients
                    </h2>

                    <div className='HR_MT'>
                        <OurClients />
                    </div>
                </div>
                <div id="integration" className="IdChangesIntegration"></div>
                <div id='integration' className='integartionPT'>
                    <Integration appName={"Employee Directory 365"} />

                </div>
                <div className="IdChangesIntegration"></div>
                <div className='HR_whitesection'>

                    <h2 className='HR_heading HR_MT' id='pricing'>Time off Manager 365 Plans
                    </h2>
                    <div className='HR_MT'>
                        <div>
                            <Plans plans={planData} />
                        </div>
                    </div>
                    <div className='AzureLogoText'>
                        <a href='https://appsource.microsoft.com/en-us/product/web-apps/hr365bizapps365.tm365p5a001?tab=PlansAndPrice' target='_blank'>
                            <img className='AzureImage' src='https://ik.imagekit.io/zn4au2jftpm5/Azure_dQeGdjXNs_cWe2-VVEx.jpeg?updatedAt=1736158710879' alt='Azure' />
                        </a>
                        <p className='AzureText'>
                            Using Azure? now you can <a href='https://appsource.microsoft.com/en-us/product/web-apps/hr365bizapps365.tm365p5a001?tab=PlansAndPrice' target='_blank'>
                                buy
                            </a>
                            on Azure Marketplace or using your enterprise agreement
                        </p>
                    </div>
                    <div className='Hr_flex HR_MT'>
                        <DemoButtons />
                    </div>
                    <SpecailButtons userCount={"500+"} />
                    <h3 className='HR_heading HR_MT'>Get started with 14 days risk free trial. <br />No credit card details required
                    </h3>

                </div>
                <span className='privacytext'></span>
                <div className='HR_panel'>
                    <div id="faq" className="IdChangesIntegration"></div>
                    <div>
                        <h3 className='HR_faq'>Frequently Asked Questions</h3>
                        <Accordion items={accordionItems} />
                    </div>


                </div>

                <Footer />
            </HelmetProvider>
        </>
    )
}
export default TM365;